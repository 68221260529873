
import {
  defineComponent,
  getCurrentInstance,
  onBeforeUnmount,
  onMounted,
  reactive,
  toRefs,
  ref,
  computed,
} from "vue";
import { useRouter, useRoute } from "vue-router"; //引入路由
import { mainServer } from "@/apiServer/installServer";
import commonFun from "@/common/ts/commonFun";
export default defineComponent({
  name: "Main",
  props: {
    viewModule: {
      type: Boolean,
      default: false,
    }, // true 为展示
    listData: {
      type: Object,
    },
  },
  setup(props, context) {
    const { proxy }: any = getCurrentInstance();
    const router = useRouter();
    const route = useRoute();
    let data = reactive({
      tableData: [],
    });

    function deleteRow(params: any) {
      console.log("deleteRow: ---->", params);
    }

    function FnButtonCallBack(item: any, Fn: any) {
      Fn(item);
    }

    // // 统一回调函数
    // function FnCallBack(item: any) {
    //   context.emit("callBack", item);
    // }

    function FnToUrl(item: any, config: any) {
      console.log('item ---->' , item);
      console.log('config ---->' , config);
      proxy.$m.vuex("routeStore", item);
      router.push({ path: config.router, query: {} });
    }

    function FnCurrentChange(e: any) {
      console.log("FnCurrentChange: e ---->", e);
      context.emit("CurrentChange", e);
    }

    onMounted(() => {
      // setInterval(()=>{
      //   console.log('props.viewModule' , props.viewModule);
      // },1000)
      console.log("listData ---->", props.listData);
    });
    onBeforeUnmount(() => {
      // proxy.$Bus.$off("Magnify");
    });
    return {
      ...toRefs(data),
      proxy,
      deleteRow,
      FnButtonCallBack,
      FnCurrentChange,
      FnToUrl
      // FnCallBack
    };
  },
});
